import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import bannerImage from "./banner.png";
import Spacer from "./Spacer";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Inter",
    backgroundColor: "#DCD3D3",
  },
  tiers: {
    display: "flex",
    flexDirection: "row",
  },
  image: {
    width: "20%",
  },
}));

const Stripe = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.tiers}>
        <img src={bannerImage} alt="Banner" className={classes.image} />
        <img src={bannerImage} alt="Banner" className={classes.image} />
        <img src={bannerImage} alt="Banner" className={classes.image} />
      </div>
    </div>
  );
};

export default Stripe;
