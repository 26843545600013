import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import logo from "./logo.png";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import "@fontsource/inter";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "#fcf3f3",
  },
  menuButton: {
    paddingRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "black",
  },
  logo: {
    width: "20%",
    marginRight: theme.spacing(2),
  },
  options: {
    display: "flex",
    position: "absolute",
    right: 0,
    textTransform: "none",
  },
  button: {
    marginRight: theme.spacing(1),
    fontWeight: 400,
    textTransform: "none",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#fcf3f3",
  },
  buttonFinal: {
    fontFamily: "Inter",
    fontWeight: 700,
    backgroundColor: "#be0c0c",
    borderRadius: "9px",
    color: "white",
    textTransform: "none",
    marginRight: theme.spacing(1),
    "&:hover": {
      opacity: "0.8",
      backgroundColor: "#be0c0c",
    },
  },
}));

const Header = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar
        elevation={0}
        position="static"
      >
        <Toolbar className={classes.toolbar}>
          <img src={logo} alt="Logo" className={classes.logo} />
          <div className={classes.options}>
            <Button className={classes.button} color="black">
              About us
            </Button>
            <Button className={classes.button} color="black">
              Our Vision
            </Button>
            <Button className={classes.buttonFinal} color="black">
              Contact us
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default Header;
