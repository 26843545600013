import React from "react";
import { Box, useTheme } from "@material-ui/core";

const Spacer = (x, y, basis, ...restProps) => {
  return (
    <Box
      style={{paddingTop: 10}}
      data-testid="Spacer"
      width={10}
      height={10}
      flexBasis={0}
      flexGrow={0}
      flexShrink={0}
      {...restProps}
    />
  );
};

export default Spacer;
