import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

import bannerImage from "./banner.png";
import Spacer from "./Spacer";

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: "Inter",
    display: "block",
    opacity: 0.9,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: "#142a26",
  },
  text: {
    // padding: theme.spacing(3),
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
    fontFamily: "Inter",
    backgroundColor: "#e58080",
  },
  stripe: {
    marginLeft: "19%",
    marginRight: "19%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    fontFamily: "Inter",
  },
}));

const Stripe = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.stripe}>
        <Typography
          variant="h4"
          style={{ color: "#f0c7ff", fontWeight: 700, textAlign: "left" }}
        >
          Maths
        </Typography>

        <Typography
          variant="h4"
          style={{ color: "#71aaff", fontWeight: 700, textAlign: "left" }}
        >
          English
        </Typography>

        <Typography
          variant="h4"
          style={{ color: "#3cb371", fontWeight: 700, textAlign: "left" }}
        >
          Science
        </Typography>

        <Typography
          variant="h4"
          style={{ color: "#ffff0d", fontWeight: 700, textAlign: "left" }}
        >
          11+
        </Typography>
      </div>
    </div>
  );
};

export default Stripe;
