import logo from "./logo.svg";
import "./App.css";
import Header from "./Header";
import Banner from "./Banner";
import Stripe from "./Stripe";
import Tiers from "./Tiers";

import "@fontsource/inter";
// #E7DDDD
function App() {
  return (
    <div
      className="App"
      style={{ backgroundColor: "#fff2f2", fontFamily: "Inter" }}
    >
      <Header />
      <Banner />
      <Stripe />
      <Tiers />

    </div>
  );
}

export default App;
