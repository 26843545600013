import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import bannerImage from "./banner.png";
import Spacer from "./Spacer";

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: "50px",
    // height: "100px",
    // paddingRight: "10%",
    paddingLeft: "10%",
    alignItems: "left",
    fontFamily: "Inter",
    paddingBottom: theme.spacing(5),
    backgroundColor: "#fff3f3"
  },
  image: {
    maxHeight: "95%",
    maxWidth: "95%",
  },
  text: {
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    alignItems: "left",
    justifyContent: "left",
    textAlign: "left",
    fontFamily: "Inter",
  },
  button: {
    // marginRight: theme.spacing(2),
    fontSize: "1.1vw",
    fontColor: "white",
    color: "white",
    fontWeight: 700,
    backgroundColor: "#007513",
    opacity: "0.8",
    textTransform: "none",
    fontFamily: "Inter",
    "&:hover": {
      opacity: "0.6",
      backgroundColor: "#007513",
    },
  },
  pitch: {
    textAlign: "left",
  },
}));

const Banner = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={6} sm={6} md={6} lg={5}>
          <img src={bannerImage} alt="Banner" className={classes.image} />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={5} className={classes.text}>
          <Typography
            variant="h3"
            style={{ fontWeight: 700, textAlign: "left" }}
          >
            Education at your fingertips.
          </Typography>

          <Spacer />
          <Spacer />

          <Typography variant="body1" className={classes.pitch}>
            Welcome to our online tuition platform!
          </Typography>

          <Spacer />
          <Typography variant="body1" className={classes.pitch}>
            We are a team of <b>experienced</b> and <b>qualified</b> tutors who are dedicated
            to helping students of all ages and abilities succeed in their
            studies.
          </Typography>
          <Spacer />
          <Spacer />
          <Spacer />
          <Spacer />
          <Button className={classes.button}>Book your first session</Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default Banner;
